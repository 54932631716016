import camelCase from './util/camelCase';
// import Bowser from 'bowser';
// import { guess } from 'guess-webpack/api';

// import Array of all Routes we want
import routes from './routes';
window.addEventListener('beforeinstallprompt', function (ev) {
  ev.preventDefault(); // Prevents immediate prompt display
});

// Wait page is loaded
window.onload = async () => {
  // Check Browser and import good polyfill
  // const browser = Bowser.getParser(window.navigator.userAgent);
  // const browserName = browser.getBrowserName().toLowerCase().replace(' ', '_');

  // try {
  //   await import(/* webpackChunkName: "polyfill", webpackPreload: true */ `./polyfills/${browserName}`);
  // } catch(error) {
  //   console.log('No polyfill for this browser.');
  // }

  // import common.js for all page
  const {
    default: common
  } = await import(/* webpackChunkName: "common", webpackPreload: true */'./routes/common');

  // Fire init from common.js
  common.init();

  // Check class on body to determine the current page
  document.body.className.toLowerCase().replace(/-/g, '_').split(/\s+/).map(camelCase).forEach(async className => {
    if (routes.includes(className)) {
      // Import right .js for the current page
      const {
        default: _
      } = await import(/* webpackChunkName: "[request]" */"./routes/".concat(className));

      // Fire init & finalize from current page .js
      _.init();
      _.finalize();
    }
  });

  // Fire finalize from common.js
  common.finalize();
};